<ion-header solid #header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start"><ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content id="loginModal" class="ion-padding">
  <ion-title class="ion-no-padding">{{'Enter your OTP code'|translate}}</ion-title>
  <ion-title class="ion-no-padding sub-title">{{'From your HSBC Gift card'|translate}}</ion-title>

  <form #loginForm="ngForm" class="form" (ngSubmit)="submit()">

    <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4,letterCase:'Upper'}"></ng-otp-input>

    <ion-item lines="none" *ngIf="error_msg!=''">
      <ion-label>
        {{error_msg| translate}}
      </ion-label>
    </ion-item>


    <ion-button class="submit" color="dark" [disabled]="!submit_btn" type="submit">
      {{ 'Submit' | translate }}
    </ion-button>
    <img class="card-img" src="./assets/images/hsbc/card_back.png"  />

  </form>

</ion-content>