<ion-header solid #header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start"><ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content id="loginModal" class="ion-padding">
  <ion-title class="ion-no-padding">{{'The current estimated waiting time'|translate}}</ion-title>


  <div class="estimated-time" lines="none" >
    <div class="title">{{'Estimated wait time for ordering'|translate}}</div>
    <div class="title">({{'Last order 18:00'|translate}})</div>
    <div class="time">{{orderStatus['waiting_time']}} {{'mins'|translate}}</div>
    <ion-icon name="timer-outline"></ion-icon>
  </div>
  <div class="current-no-title">{{'Current order number'|translate}}</div>
  <div class="current-no" lines="none" >
    <div class="order-no">{{orderStatus['current_no']|translate}}</div>
  </div>
  <div class="footer">{{'This is for reference only but not the actual waiting time.'|translate}}</div>


  <ion-button class="submit" color="dark"  type="submit" (click)="next()">
    {{ 'Next' | translate }}
  </ion-button>


</ion-content>